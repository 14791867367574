// jQuery(function ($) {
//     // Event handler for Pay Now Button
//     $('#paymentform').submit(function (event) {
//         var $Scripts = $('#checkoutscript'); //Used to get values from script

//         var $Params = "?pds-key=" + $Scripts.attr('pds-key') +
//             "&pds-image=" + $Scripts.attr('pds-image') +
//             "&pds-name=" + $Scripts.attr('pds-name') +
//             "&pds-description=" + $Scripts.attr('pds-description') +
//             "&pds-button=" + $Scripts.attr('pds-button') +
//             "&pds-amount=" + $Scripts.attr('pds-amount') +
//             "&pds-theme=" + $Scripts.attr('pds-theme') +
//             "&pds-paymenttypes=" + $Scripts.attr('pds-paymenttypes') +
//             "&pds-email=" + $Scripts.attr('pds-email') +
//             "&pds-pinless=" + $Scripts.attr('pds-pinless') +
//             "&pds-fees=" + $Scripts.attr('pds-fees') +
//             "&pds-pinlesscredit=" + $Scripts.attr('pds-pinlesscredit');

//         var $HTML = "<iframe " +
//             "id='paymentapp' " +
//             "src='https://checkout.securepds.com/checkout/payment.aspx" + $Params + "' " +
//             "frameborder='0' " +
//             "onload='scrollTo(0,0)' " +
//             "style='background: rgba(0, 0, 0, 0.5); " +
//             "margin: 0px; " +
//             "padding: 0px; " +
//             "border: 0px transparent; " +
//             "border-image: none; " +
//             "left: 0px; " +
//             "top: 0px; " +
//             "width: 100%; " +
//             "height: 100%; " +
//             "display: block; " +
//             "visibility: visible; " +
//             "position: fixed; " +
//             "z-index: 2147483647; " +
//             "-ms-overflow-x: hidden; " +
//             "-ms-overflow-y: auto; " +
//             "-webkit-touch-callout: none; " +
//             "-webkit-tap-highlight-color: transparent;' " +
//             "allowtransparency='true' >" +
//             "</iframe>"

//         //var $HTML = "<iframe " +
//         //                "id='paymentapp' " +
//         //                "src='payment.aspx" + $Params + "' " +
//         //                "frameborder='0' " +
//         //                "style='background: rgba(0, 0, 0, 0.5); " +
//         //                    "margin: 0px; " +
//         //                    "padding: 0px; " +
//         //                    "border: 0px transparent; " +
//         //                    "border-image: none; " +
//         //                    "left: 0px; " +
//         //                    "top: 0px; " +
//         //                    "width: 100%; " +
//         //                    "height: 100%; " +
//         //                    "display: block; " +
//         //                    "visibility: visible; " +
//         //                    "position: fixed; " +
//         //                    "z-index: 2147483647; " +
//         //                    "-ms-overflow-x: hidden; " +
//         //                    "-ms-overflow-y: auto; " +
//         //                    "-webkit-touch-callout: none; " +
//         //                    "-webkit-tap-highlight-color: transparent;' " +
//         //                "allowtransparency='true' >" +
//         //            "</iframe>"

//         $('body').append($HTML);

//         // Prevent the form from submitting with the default action
//         return false;
//     });

//     //Add button to form
//     var $Scripts = $('#checkoutscript');
//     $("head").append($('<link href="https://checkout.securepds.com/checkout/checkout.css" rel="stylesheet" />'));
//     var $Theme = "themeBlue";
//     switch ($Scripts.attr('pds-theme').toLowerCase()) {
//         case "blue":
//             $Theme = "themeBlue";
//             break;
//         case "darkblue":
//             $Theme = "themeDarkBlue";
//             break;
//         case "aqua":
//             $Theme = "themeAqua";
//             break;
//         case "green":
//             $Theme = "themeGreen";
//             break;
//         case "yellow":
//             $Theme = "themeYellow";
//             break;
//         case "orange":
//             $Theme = "themeOrange";
//             break;
//         case "red":
//             $Theme = "themeRed";
//             break;
//         case "fuchsia":
//             $Theme = "themeFuchsia";
//             break;
//         case "purple":
//             $Theme = "themePurple";
//             break;
//         case "gray":
//             $Theme = "themeGray";
//             break;
//         case "black":
//             $Theme = "themeBlack";
//             break;
//         default:
//             $Theme = "themeBlue";
//             break;
//     }

//     var $form = $('#paymentform');
//     $form.append($('<button class="button ' + $Theme + '" type="submit" >' + $Scripts.attr('pds-button') + '</button>'));
// });


function receiveMessage(event) {
    if (event.origin !== "https://checkout.usiopay.com") {
        // alert(event.origin);
    }
    else {
        var $IFrame = $('#paymentapp');
        $IFrame.attr('src', '');
        $IFrame.remove();

        if (event.data != 'cancel') {
            var values = event.data.split("|");
            var $form = $('#paymentform');
            $('#pdsToken', $form).remove();
            $form.append($('<input type=\"hidden\" name=\"pdsToken\" id=\"pdsToken\" />').val(values[0]));
            $form.append($('<input type=\"hidden\" name=\"pdsPmtType\" id=\"pdsPmtType\" />').val(values[1]));
            $form.get(0).submit();
        }
    }
}
window.addEventListener("message", receiveMessage, false);
